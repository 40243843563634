import React, { useCallback } from 'react';
import { HStack, Text, Button, FlatList, VStack } from 'native-base';

interface Dataset {
  datasetID: string;
}

interface DatasetSelectorProps {
  filteredDatasets: Dataset[];
  selectedDatasetIds: string[];
  onDatasetSelect: (datasetId: string) => void;
  onSelectAll: () => void;
}

const DatasetSelector: React.FC<DatasetSelectorProps> = ({
  filteredDatasets,
  selectedDatasetIds,
  onDatasetSelect,
  onSelectAll
}) => {
  const renderDatasetItem = useCallback(({ item }: { item: Dataset }) => (
    <HStack justifyContent="space-between" alignItems="center" mb={2}>
      <Text fontWeight="bold">{item.datasetID}</Text>
      <Button onPress={() => onDatasetSelect(item.datasetID)}>
        {selectedDatasetIds.includes(item.datasetID) ? "Deselect" : "Select"}
      </Button>
    </HStack>
  ), [onDatasetSelect, selectedDatasetIds]);

  return (
    <>
      <VStack space={4}>
        <HStack justifyContent="space-between" alignItems="center">
          <Text fontSize="lg" fontWeight="semibold">Select Datasets:</Text>
          <Button onPress={onSelectAll}>
            {selectedDatasetIds.length === filteredDatasets.length ? "Deselect All" : "Select All"}
          </Button>
        </HStack>
        <FlatList
          data={filteredDatasets}
          renderItem={renderDatasetItem}
          keyExtractor={(item) => item.datasetID}
        />
      </VStack>
    </>
  );
};

export default DatasetSelector;
