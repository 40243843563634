import * as React from 'react';
import { Button, HStack, VStack } from 'native-base';
import MaterialIconByName from '../MaterialIconByName';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { downloadImageToFs } from 'src/utils/download';

const min = Math.min(window.innerWidth, window.innerHeight);

const width = min;
const height = min;

export default function ZoomableImage({ imgSrc,
    imgHeight,
    imgWidth,
    cache,
    priority,
    timestamp
 }: {
        imgSrc: string | undefined,
        imgHeight: number,
        imgWidth: number,
        cache: boolean,
        priority: string,
        timestamp?: number | undefined
    }) {

    const [downloadingImage, setDownloadingImage] = React.useState(false);

    const DOWNLOAD_TIMEOUT = 3 * 1000;

    const downloadImage = async () => {
        setDownloadingImage(true)
        setTimeout(() => {
            setDownloadingImage(false)
        }, DOWNLOAD_TIMEOUT)

        if (imgSrc === undefined) {
            return
        }

        await downloadImageToFs(imgSrc, "image")
    }

    return <TransformWrapper
        initialScale={1}
        minScale={1}
        initialPositionX={0}
        initialPositionY={0}
    >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
                <VStack style={{
                    alignItems: 'center',
                }}>
                    <HStack
                        style={{
                            justifyContent: 'center',
                            marginBottom: 4,
                            marginTop: 4
                        }}
                        space={1}>
                        <Button
                            startIcon={<MaterialIconByName name="close" />}
                            onPress={() => {
                                resetTransform();
                            }}>
                            Reset
                        </Button>
                        <Button
                            startIcon={<MaterialIconByName name="minus" />}
                            onPress={() => {
                                zoomOut();
                            }}>
                            Zoom Out
                        </Button>
                        <Button
                            startIcon={<MaterialIconByName name="plus" />}
                            onPress={() => {
                                zoomIn();
                            }}>
                            Zoom In
                        </Button>
                        <Button
                            startIcon={<MaterialIconByName name="download" />}
                            isLoading={downloadingImage}
                            isLoadingText="Downloading"
                            onPress={downloadImage}>
                            Download image
                        </Button>
                    </HStack>
                    <TransformComponent
                        wrapperStyle={{ maxWidth: "100%", maxHeight: "calc(100vh - 50px)" }}>
                        <img
                            height={height}
                            style={{
                                maxHeight: "calc(100vh - 50px)",
                                maxWidth: "100%",
                                objectFit: "fill",
                            }}
                            src={`${imgSrc}?timestamp=${timestamp}`}
                             />
                    </TransformComponent>
                </VStack>
            </React.Fragment >
        )
        }
    </TransformWrapper >
}