import { Spinner, Heading, VStack, Text } from 'native-base';
import React from 'react';
import { Modal } from 'react-native';

interface ProgressBarProps {
    isVisible: boolean;
    withoutOverlay?: boolean;
    progressText?: string;
    progressSubText?: string;
}

export default function ProgressBar(props: ProgressBarProps) {

    const [spinnerVisible, setSpinnerVisibility] = React.useState(props.isVisible);
    const close = () => {
        setSpinnerVisibility(false);
    };

    const _handleOnRequestClose = () => {
        close();
    };

    React.useEffect(() => {
        setSpinnerVisibility(props.isVisible);
    }, [props.isVisible]);

    return (
        <>
            {
                !props.withoutOverlay && <Modal
                    presentationStyle='overFullScreen'
                    animationType={"none"}
                    onRequestClose={() => {
                        _handleOnRequestClose();
                    }}
                    style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        height: "100%",
                    }}
                    transparent={true}
                    visible={spinnerVisible}
                    statusBarTranslucent={true}
                >
                    <VStack space={3} style={{
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}>
                        <Spinner accessibilityLabel={props.progressText} />
                        <Heading
                            size="sm">{props.progressText}</Heading>
                        <Text style={
                            {
                                textAlign: "center",
                                width: "100%",
                            }
                        }>{props.progressSubText}</Text>
                    </VStack>
                </Modal>
            }
            {
                props.withoutOverlay
                && spinnerVisible
                && <VStack space={3} style={{
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                    width: "100%",
                }}>
                    <Spinner accessibilityLabel={props.progressText} />
                    <Heading
                        size="sm">{props.progressText}</Heading>
                    <Text style={
                        {
                            textAlign: "center",
                            width: "100%",
                        }
                    }>{props.progressSubText}</Text>
                </VStack>
            }
        </>
    );
}